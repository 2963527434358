import React, { useState } from "react";

import { EmailOnlyFormProps, IterableDataType } from "@types";
import { formInitialCtx } from "../../../contexts/FormOnlyEmail.context";
import { mxpPrimaryCtaClicked } from "../../../utils/mixpanelEvents/mixpanelEvents";

import EmailOnlyFormProvider from "../EmailOnlyFormProvider";

import styles from "./HeroFormSection.module.scss";
import { iterableSubmit } from "../../../utils/iterableSubmit";
import { getFormattedTime } from "../../../assets/util-scripts/helpers";
import { useSignUpFormNavigate } from "@hooks/useSignUpFormNavigate";
import { leadCapture } from "@utils/segment/leadCapture";

type HeroFormSectionType = {
  buttonLink: string;
  buttonText: string;
  className?: string;
  placeholder: string;
};

const HeroFormSection: React.FC<HeroFormSectionType> = ({
  className,
  placeholder,
  buttonText,
  buttonLink,
}) => {
  const [email, setEmail] = useState<string>("");
  const signUpFormNavigate = useSignUpFormNavigate();
  const submitEmailData: IterableDataType = {
    email: email,
    SIGN_UP_SOURCE: "Website - Sign Up CTA",
    startTrialFlowAt: getFormattedTime(),
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setEmail(e.target.value);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    window.analytics?.identify({
      account_status: "lead",
      email,
    });

    const analyticsData = {
      text: buttonText,
      email: email,
    };

    leadCapture(analyticsData);

    iterableSubmit(submitEmailData);
    mxpPrimaryCtaClicked(buttonLink, ctx.buttonText, {
      email,
      withEmailField: true,
    });

    signUpFormNavigate(email, buttonLink);
  };

  const ctx: EmailOnlyFormProps = {
    ...formInitialCtx,
    email,
    buttonText,
    placeholder,
    handleSubmit,
    handleChange,
    withArrow: true,
  };

  return (
    <section
      className={`${styles.formSection} ${className}`}
      data-testid="hero-form"
    >
      <EmailOnlyFormProvider ctxInitialVal={ctx} />
    </section>
  );
};

export default HeroFormSection;
